import React, { ReactNode, useState } from "react";
import { StyleSheet, View } from "react-native";

import NativeSelect from '@mui/material/NativeSelect';
import { styled } from '@mui/material/styles';

interface INativeSelectProps {
    check: string;
    selectedStatus: string | null; 
    onStatusChange: (status: string) => void;
}

export default function CustomNativeSelect({ check ,selectedStatus, onStatusChange }: INativeSelectProps) {

    const CustomNativeSelect = styled(NativeSelect)({
        '&:before': {
          borderBottom: 'none', 
        },
        '&:after': {
          borderBottom: 'none', 
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: 'none', 
        }
      });

      const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        if (value !== selectedStatus) {
            onStatusChange(value); 
        }
    };

    return (
        <CustomNativeSelect
            onChange={handleStatusChange}
            value={selectedStatus || check}

            inputProps={{
                name: 'age',
                id: 'uncontrolled-native',
            }}
            sx={{ height: '57px', fontSize: '20px', border: '0px solid white', color: '#475569', paddingLeft: '20px' }}
        >
            <option value="in_transit">In Transit</option>
            <option value="Weather Delay">Weather Delay</option>
            <option value="road_closure">Road Closure</option>
            <option value="break_down">Break Down</option>
            <option value="delivered">Delivered</option>
            <option value="pending">Pending</option>
        </CustomNativeSelect>
    );
}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    paddingVertical: 16
  },
  bannerBg: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
  },
});