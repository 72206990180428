import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Grid,
    Stepper,
    Step,
    StepLabel,
    StepConnector,
    Button,
    Avatar,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControl,
    MenuItem,
    Select,
    Divider,
    Menu,
    Dialog,
} from "@mui/material";
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import {
  styled, createTheme, ThemeProvider
} from "@mui/material/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import HeaderNavigation from "../../navigationmenu/src/HeaderNavigation.web";
import MyLoadsController, {
    Props,
    configJSON
} from "./MyLoadsController.web";
import { trcukdetailsIcon, truckIcon, sendIcon, person } from "./assets";
import {Clear } from "@mui/icons-material";
import { ChatContainer, 
  MessageList, 
  MessageInput, 
  ConversationHeader, 
  Message, Avatar as ChatIcon
 } from '@chatscope/chat-ui-kit-react';
import {MoreVert, Circle, ExpandMoreRounded, KeyboardArrowDown, ExpandLessRounded, Phone, ChatBubbleOutlineOutlined, LocationOnOutlined, Edit, DeleteOutline, Close} from '@mui/icons-material';
import {IMessageList} from "./MultilevelApprovalController.web"
const BpIcon1 = styled('span')(({ theme }) => ({
  height: 20,
  width: 20,
  borderRadius: "6px",
  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  marginLeft:"-9px",
  'input:hover ~ &': {
      backgroundColor: '#EBF1F5',
  },
  'input:disabled ~ &': {
      boxShadow: 'none',
  },
}));
const BpCheckedIcon1 = styled(BpIcon1)({
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  backgroundColor: '#B57730',
  '&::before': {
    height: 20,
    width: 20,
    display: 'block',
      backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
  },
  'input:hover ~ &': {
      backgroundColor: '#B57730',
  },
});
const BpCheckbox1 = (props: CheckboxProps) => {
  return (
      <Checkbox
      checkedIcon={<BpCheckedIcon1 />}
      color="default"
      icon={<BpIcon1 />}
      disableRipple
          inputProps={{ 'aria-label': 'list-data' }}
          {...props}
      />
  );
}
const theme = createTheme({
  components: {
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor:'#f1f5f9',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #CBD5E1',               
        },}
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: 'white',
          },
          '&:focus-visible': {
            outline: 'none', 
          },
          '&.Mui-selected':{
            backgroundColor: 'white',
            '&:hover': {
              backgroundColor: 'white',
            },
          },
        },
      },
    },
    MuiAccordionDetails:{
      styleOverrides: {
      root:{
        backgroundColor: 'white',
        padding: '0 10px 0 0',
        
      }
      }
    },
    MuiButton:{
      styleOverrides: {
      root:{ 
        '&:focus-visible': { outline: 'none', },       
        '&:hover': {
          backgroundColor: '#FBE1C4',
        }        
      }
      }
    },
    MuiSvgIcon:{
      styleOverrides: {
      root:{        
        fontSize: '30px',      
      }
      }
    },
  },
});
const MainContainerBox:any = styled(Box)(({ theme })=> ({
  display: "flex",
  flexDirection: "column",
  height: "1124px",
  marginTop: '50px',
  "*":{
    fontFamily:"inter"
  },
  ".cs-status--available .cs-status__bullet":{
     background:"#80EA4E"
  },
  ".cs-conversation__name":{
    fontWeight:700,
    fontSize:18,
    color:"#1E293B",
    marginBottom:10,
  },
  ".cs-conversation__info-content":{
    fontSize:16,
    color:"#78716C",
    lineHeight:2
  },
  
  ".cs-main-container > .cs-sidebar.cs-sidebar--left":{
    maxWidth:"547px",
    border:0,
    width:"40%",
  },
  ".cs-conversation__last-activity-time":{
    alignItems:"flex-start"
  },
  ".cs-conversation__content":{
    flexGrow:0,
    width:"50%"
  },
  ".cs-conversation-header":{
    borderRadius:25,
    background:"#215089",
    padding:"30px"
  },
  ".cs-conversation-header__actions":{
    color:"#ffffff",
    ".css-i4bv87-MuiSvgIcon-root":{
      fontSize:"2.5rem"
    }
  },
  ".cs-conversation-header__content":{
    justifyContent:"space-around"
  },
  ".cs-conversation-header__avatar, .cs-avatar.cs-avatar--md":{
    height:78,
    width:78
  },
  ".cs-conversation-header__user-name":{
    fontSize:24,
    background:"#215089",
    color:"#FFFFFF",
    fontWeight:700
  },
  ".cs-conversation-header__info":{
    fontSize:16,
    background:"#215089",
    color:"#FFFFFF",
    fontWeight:400
  },
  ".cs-main-container":{
    border:0,
    height:"auto!important"
  },
  ".cs-chat-container":{
    height:"auto",
    border:"1px solid #DEE5ED",
    borderRadius:24,
    marginLeft:25,
    overflowY:'auto'
    
  },
  ".cs-message--incoming .cs-message__content": {
    fontSize:18,
    backgroundColor: "#E9EEF3",
    padding:"30px 20px",
    marginTop:20
  },
  ".cs-message--outgoing .cs-message__content":{
    padding:"30px 20px",
    backgroundColor: "#BAC9DA",
    fontSize:18,
    marginTop:20
  },
  ".cs-message-input":{
    boxShadow:"4px 4px 0px 0 #00000033",
    borderTop:0,
    margin:20,
    borderRadius:36,
    marginBottom:30 ,
  },
  ".cs-message-input__content-editor-wrapper,.cs-message-input__content-editor":{
    backgroundColor:"#fff"
  },
  ".cs-button--attachment, .cs-button--send": {
    alignSelf:"center",
    color: "#A8A29E",
  },
  ".cs-search":{
    borderRadius:4,
    backgroundColor:"#fff",
    maxWidth:344,
    border:"1px solid #CBD5E1",
  },
  ".cs-search__input":{
    backgroundColor:"#fff",
    "& ::placeholder":{
      color:"#94A3B8"
    }
  },
  ".cs-search__search-icon":{
    color:"#94A3B8"
  },

  ".custom-send-button": {
    background: "none",
    border: "none",
    position: "relative",
    left: "220px",
    bottom:'60px',
    cursor: "pointer",
  },
  ".cs-back-button": {
    fontSize: "1rem", 
  },
})) ;

// Customizable Area End

export default class MyLoads extends MyLoadsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderApproveDialog = () => {
      return (
          <Dialog
              open={this.state.isOpenApprove}
              PaperProps={{ style: webStyles.dialogStyle }}
              onClose={this.handleCloseApproveModal}
              data-testId={"openApproveModal"}
              maxWidth="md"
          >
              <Box display={'flex'} justifyContent={'flex-end'}>
                  <Close onClick={this.handleCloseApproveModal} />
              </Box>
              <Typography sx={{...webStyles.flexText, fontSize:'24px', margin: '10px 0 24px 0'}}>Are you sure you want to approve this trucker "<span style={{textTransform:"capitalize"}}>{this.state.applicantName}</span>"?</Typography>
              <Divider style={{margin:'0 -40px'}}/>
              <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} gap={'20px'}
                  sx={{ marginTop: '2rem' }}
              >
                  <Button variant="contained" data-testId = "closeApproveModal" sx={{...webStyles.cancelButton, height: '56px', width: '130px',}} onClick={this.handleCloseApproveModal}>NO</Button>
                  <Button variant="contained" data-testId = "handleApproved" sx={{...webStyles.LogoutButton, height: '56px', width: '130px',}} onClick={this.handleApproved}>YES</Button>
              </Box>
          </Dialog>
      )
  }
    renderRejectDialog = () => {
      return (
        <Dialog
            PaperProps = {{ style: webStyles.dialogStyle }}
            open = {this.state.isOpenReject}
            maxWidth = "md"
            onClose = {this.handleCloseRejectModal}            
        >
            <Box display = {'flex'} justifyContent = {'flex-end'}>
                <Close onClick = {this.handleCloseRejectModal} />
            </Box>
            <Typography sx={{...webStyles.flexText, fontSize:'24px',margin: '10px 0 24px 0'}}>Are you sure you want to reject this trucker{' '} 
              <span style={{textTransform:"capitalize", whiteSpace: 'nowrap'}}>"{this.state.applicantName}"?</span>
            </Typography>
            <Divider style={{margin:'0 -40px'}}/>
            <Box display={'flex'} justifyContent={'flex-end'} gap={'20px'} alignItems={'center'} sx={{ marginTop: '2rem' }} >
                <Button sx={{...webStyles.cancelButton, height: '56px', width: '130px',}} data-testId = {"closeRejectModal"} variant="contained" onClick={this.handleCloseRejectModal}>NO</Button>
                <Button sx={{...webStyles.LogoutButton, height: '56px', width: '130px',}} data-testId = {"handleRejected"} variant="contained" onClick={this.handleRejected}>YES</Button>
            </Box>
        </Dialog>
      )
    }
    renderDeleteDialog = () => {
      return (
        <Dialog
            PaperProps = {{style: webStyles.dialogStyle}}
            onClose = {this.handleCloseDeleteModal}            
            open = {this.state.isOpenDelete}
            maxWidth = "md"
        >
            <Box display={'flex'} justifyContent={'flex-end'}>
                <Close onClick={this.handleCloseDeleteModal}/>
            </Box>
            <Typography sx={{...webStyles.flexText, fontSize:'24px',margin: '10px 0 24px 0'}}>Are you sure you want to delete this Load?</Typography>
            <Divider style={{margin:'0 -40px'}}/>            
            <Box display={'flex'} justifyContent={'flex-end'} gap={'20px'} alignItems={'center'} sx={{ marginTop: '2rem' }} >
                <Button sx={webStyles.cancelButton} data-testId = {"closeDeleteModal"} variant="contained" onClick={this.handleCloseDeleteModal}>CANCEL</Button>
                <Button sx={webStyles.LogoutButton} data-testId = {"handleDeleted"} variant="contained" onClick={this.handleDeleted}>DELETE</Button>
            </Box>
        </Dialog>
      )
    }
    chatScreenOpen = ()=>{
      const { particularChat } = this.state
    const { messages } = particularChat
    const {fileUploaded} = this.state;
      return(
        <MainContainerBox data-data-id="chat-main-container">
          <ChatContainer>
            <ConversationHeader>
              <ConversationHeader.Back onClick={this.closeChat} className="cs-back-button" data-testId='close-chat'/>
              <ChatIcon
                name="Zoe"
                src={particularChat.user_profile_image}
                status={particularChat.other_user_is_online ? "available" : "invisible"}
              />
              <ConversationHeader.Content
                 info={particularChat.other_user_is_online ? "Active Now" : ""}
                userName={this.state.applicantName}
              />
              <ConversationHeader.Actions>
              </ConversationHeader.Actions>
            </ConversationHeader>
            <MessageList>
          {messages?.map((mList: IMessageList) => (
            <Message
              key={mList.id}
              model={{
                direction: mList.attributes.account_id === +this.loginId ? "outgoing" : "incoming",
                message: mList.attributes.message,
                position: 'single',
                sentTime: mList.attributes.created_at,
                sender: 'Zoe',
              }}

            ><Message.CustomContent >
                {mList.attributes.attachments ? (
                  mList.attributes.attachments?.map((attach) => (
                    <Box sx={{display:"flex", flexDirection:"column"}}>
                      <img src={attach.url}  style={webStyles.imageStyle} alt="file-uploaded" />
                      <span>{mList.attributes.message}</span>
                    </Box>
                  ))) : <span>{mList.attributes.message}</span>}
                </Message.CustomContent>
            </Message>    ))}
        </MessageList>
        <MessageInput
          placeholder="Type a message"
          data-test-id="message_input"
          onSend={this.onSendMessage}
          onChange={(innerHtml, textContent, innerText) => this.handleOnChange(innerHtml, textContent, innerText)}
          onAttachClick={this.handleOnAttachClick}
        />
          </ChatContainer>
          {fileUploaded?.type?.startsWith("image/") ? (
                      <Box sx={webStyles.imagePreview}>
                        <Clear data-test-id = "clearBtn" onClick={this.handleOnClear} sx={webStyles.clearButton} />
                        <img src={URL.createObjectURL(fileUploaded)} style={{ borderRadius: '8px', width: "100%" }} />
                      </Box>
                    ) : <></>}
        </MainContainerBox>       )}
    // Customizable Area End

    render() {
        // Customizable Area Start
        const selectedCard = this.state.loadsDataList.find(card => card.id === this.state.selectedCardId);
        const open = Boolean(this.state.menuAnchorEl);
        const isAllSelected =
        this.state.statuses.length > 0 && this.state.selectedStatuses.length === this.state.statuses.length;
        return (
            //Merge Engine DefaultContainer
            <>
            <Box sx={webStyles.container}>
                <HeaderNavigation navigation={this.props.navigation} id={""} />
                <Box sx={webStyles.content}>
                    <NavigationMenu navigation={this.props.navigation} id={""} />
                    <ThemeProvider theme={theme}>
                    <Box sx={webStyles.mainContent}>
                        <Box sx={webStyles.tabContainer}>
                            <Typography style={webStyles.headerFont}>{configJSON.myLoadsText}</Typography>
                            <Box sx={{
                              width: '327px'
                            }}>
                              <FormControl sx={{ m: 1, width: 300 }}>

                                <Select
                                  labelId="demo-multiple-name-label"
                                  id="demo-multiple-name"
                                  value={this.state.selectedStatuses}
                                  onChange={this.handleChange}
                                  multiple
                                  data-testId = 'selectFilter'
                                  style={webStyles.input}
                                  MenuProps={{
                                    PaperProps: {
                                      sx: {
                                        padding: '20px', 
                                        width: '20ch',
                                        backgroundColor:'#f1f5f9',
                                      },
                                    },
                                  }}
                                  displayEmpty={true}
                                  renderValue={(selected) => {
                                    if (selected.length === 0) {
                                      return <span style={webStyles.selectStyle}>Filter Status</span>;
                                    }
                                    return selected.join(', ');
                                  }}
                                  IconComponent={KeyboardArrowDown}
                                >
                                  <MenuItem
                                        key={'All'}
                                        value={'All'}
                                      >
                                        <BpCheckbox1 checked={isAllSelected}/>
                                        {'All'}
                                      </MenuItem>
                                  {this.state.statuses.map((status) => (
                                      <MenuItem
                                        key={status}
                                        value={status}
                                      >
                                        <BpCheckbox1 checked={this.state.selectedStatuses.includes(status)}/>
                                        {status}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </Box>
                            {this.state.loadsDataList.length > 0 ?
                            <Grid container spacing={1}>
                              <Grid item lg={7} md={12} sm={12} xs={12} sx={webStyles.styledCardContainer}>
                                {this.state.loadsDataList.map((cardItem) => (
                                  <Box
                                    sx={{
                                      ...webStyles.cardStyle,
                                      border: this.getBorder(cardItem.id),
                                      boxShadow: this.getBoxShadow(cardItem.id),
                                    }}
                                    key={cardItem.id}
                                    data-testId='load-cards'
                                    onClick={() => this.handleCardClick(cardItem.id)}
                                  >
                                    <Box sx={webStyles.cardHeader}>
                                      <Box sx={webStyles.avatarContainer}>
                                      <Typography sx={{...webStyles.fontStyle_6, fontSize: '24px'}}>#ID {cardItem.id}</Typography>
                                        <MoreVert onClick={(event)=>this.handleButtonClick(event, cardItem.id)}/>
                                      </Box>
                                    </Box>
                                    {this.state.selectedCardId === cardItem.id && (<Menu
                                      id="edit-menu"
                                      anchorEl={this.state.menuAnchorEl}
                                      open={open}
                                      onClose={this.handleEditMenuClose}
                                      PaperProps={{
                                        sx: {                                         
                                          backgroundColor: 'yellow',
                                          borderRadius:'8px',
                                          width:'126px',
                                          maxHeight: 48 * 4
                                        }
                                      }}
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                      }}
                                    >
                                      <Box style={{padding: '4px',width:'126px',}}>
                                      <MenuItem sx={{                                          
                                          backgroundColor: '#fff', borderRadius:'8px', '&:hover': { backgroundColor: '#FDF1E2', }
                                        }}>
                                          <Box style={{alignItems:'center',display:'flex'}} onClick={()=>{this.handleNavigation('PostCreation', cardItem.id)}}>
                                            <Edit style={{fontSize:'18px', paddingRight:'10px'}}/>
                                            <Typography style={{...webStyles.flexText, fontWeight: 400,fontSize:'16px', }}>Edit</Typography>
                                          </Box>
                                      </MenuItem>
                                      <MenuItem onClick={this.handleEditMenuClose} sx={{                                          
                                          ...webStyles.flexText, fontWeight: 400, backgroundColor: '#fff', borderRadius:'8px', '&:hover': { backgroundColor: '#FDF1E2', }
                                        }}>
                                          <Box style={{alignItems:'center',display:'flex'}} onClick={()=>{this.openDeleteModel(cardItem.id)}}>
                                            <DeleteOutline style={{fontSize:'19px', paddingRight:'10px'}}/>
                                            <Typography style={{...webStyles.flexText, fontWeight: 400,fontSize:'16px',paddingTop:'5px' }}>Delete</Typography>
                                          </Box>                                        
                                      </MenuItem>
                                      </Box>
                                    </Menu>)}
                                    <Box display={'flex'} flexDirection={'column'} sx={webStyles.boxPadding}>
                                    <Box style={{...webStyles.justifySpace, marginBottom:'20px'}} alignItems='center' >
                                      <Box >
                                        <Typography sx={webStyles.fontSyle_4}
                                        >{this.formatLoadWeight(cardItem.attributes.load_weight)} - {this.formatLoadMiles(cardItem.attributes.total_miles)}</Typography>
                                      </Box>
                                      <Box >
                                        <Typography sx={{
                                          ...webStyles.fontSyle_3,
                                        }}
                                        >
                                          No of Applicants : {cardItem.attributes.applications_count}
                                        </Typography>
                                      </Box>                                      
                                      </Box>  
                                      <Box sx={{ ...webStyles.justifySpace,alignItems:'center', }}>
                                        <Box sx={webStyles.statusBox} >
                                          <Stepper
                                            orientation="vertical"
                                            connector={<CustomConnector />}
                                          >
                                              <Step>
                                                <StepLabel
                                                  style={{padding:0}}
                                                  icon={<div style={{borderRadius:'50%', backgroundColor:'#b4c8d9', height:'10px', width:'10px'}}></div>}
                                                >
                                                  <Typography sx={webStyles.dateFontSyle_1}>{cardItem.attributes.origin_address?.city}</Typography>
                                                </StepLabel>
                                              </Step>
                                              <Step >
                                              <StepLabel style={{padding:0}}
                                                icon={<Circle sx={{fontSize:"11px"}} /> }
                                              >
                                                <Typography sx={webStyles.dateFontSyle_1}>{cardItem.attributes.destination_address?.city}</Typography>
                                              </StepLabel>
                                            </Step>
                                          </Stepper>
                                        </Box>
                                        <Typography style={webStyles.priceText}>$ {parseFloat(cardItem.attributes.price).toFixed(0)}</Typography>
                                      </Box>
                                    </Box>

                                    <Box sx={webStyles.viewBox}>
                                      <Typography sx={{...webStyles.fontSyle_5, textTransform:'capitalize'}}>{(cardItem.attributes.status).replace('_', ' ')}</Typography>
                                      <Button sx={{...webStyles.buttonStyle, textTransform:'none'}}>View Details</Button>
                                    </Box>

                                  </Box>
                                ))}
                              </Grid>
                              <Grid item lg={5} md={9} sm={12} xs={12}>
                              {this.state.isChatShow ? this.chatScreenOpen() :
                              selectedCard && (
                                <Box sx={webStyles.driverInfoCard}>
                                  <Box sx={webStyles.infoBoxPadding}>
                                  <Box sx={webStyles.mainTruckBox}>
                                    <Box sx={webStyles.trackingBoxStyle}>
                                      <Box sx={{...webStyles.boxColumn, marginRight: '60px'}}>
                                        <Box sx={webStyles.trackingDetails}>
                                          <Typography sx={{...webStyles.fontStyle_5, fontSize: '12px', width:'max-content'}}>{configJSON.idNo}</Typography>
                                          <Typography sx={webStyles.fontStyle_6}>{selectedCard.id}</Typography>
                                        </Box>

                                        <Box sx={webStyles.trackingDetails}>
                                          <Typography sx={{...webStyles.fontStyle_5, fontSize: '12px', width:'max-content'}}>{configJSON.from}</Typography>
                                          <Typography sx={webStyles.fontStyle_6}>{selectedCard.attributes.origin_address?.city}</Typography>
                                        </Box>

                                        <Box sx={webStyles.trackingDetails}>
                                          <Typography sx={{...webStyles.fontStyle_5, fontSize: '12px', width:'max-content'}}>{configJSON.weight}</Typography>
                                          <Typography sx={webStyles.fontStyle_6}>{this.formatLoadWeight(selectedCard.attributes.load_weight)}</Typography>
                                        </Box>
                                      </Box>

                                      <Box sx={webStyles.boxColumn}>
                                        <Box sx={webStyles.trackingDetails}>
                                          <Typography sx={{...webStyles.fontStyle_5, fontSize: '12px', width:'max-content'}}>{configJSON.trukerApplied}</Typography>
                                          <Typography sx={webStyles.fontStyle_6}>{selectedCard.attributes.applications_count}</Typography>
                                        </Box>

                                        <Box sx={webStyles.trackingDetails}>
                                          <Typography sx={{...webStyles.fontStyle_5, fontSize: '12px', width:'max-content'}}>{configJSON.to}</Typography>
                                          <Typography sx={webStyles.fontStyle_6}>{selectedCard.attributes.destination_address?.city}</Typography>
                                        </Box>

                                        <Box sx={webStyles.trackingDetails}>
                                          <Typography sx={{...webStyles.fontStyle_5, fontSize: '12px', width:'max-content'}}>{configJSON.amount}</Typography>
                                          <Typography sx={webStyles.fontStyle_6}>${parseFloat(selectedCard.attributes.price).toFixed(0)}</Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box display={'flex'} justifyContent={'flex-end'}>
                                          <img src={trcukdetailsIcon} width='315px' height='165px'/>
                                      </Box>
                                      </Box>
                                    <Box style={{ overflow: "hidden", backgroundColor: '#e9eef2', marginBottom:'40px' }}>
                                        <Accordion style={webStyles.allSelectColor}>
                                            <Box style={{ padding: '20px' }}>
                                                <AccordionSummary expandIcon={<ExpandMoreRounded />}>
                                                    <Typography style={webStyles.allFilterFont}>Applicants</Typography>
                                                </AccordionSummary>
                                            </Box>
                                            <AccordionDetails>
                                              <Box style={{ ...webStyles.coverBox2, flexDirection: 'column', 
                                                overflowY: this.getOverflow(), 
                                                maxHeight: this.getHeight(),
                                                }}>
                                                {this.state.expandedApplicantId === '0' && this.state.applicantsList.length > 0 &&
                                                  <Typography style={{ ...webStyles.fontStyle_5, color: '#465567' }}>Here you can see the details of the load applicants you want to approve or reject.</Typography>
                                                }
                                                {this.state.applicantsList.map((applicant) =>(
                                                  <>
                                                  {console.log("heel",applicant)}
                                                  {this.state.expandedApplicantId === '0' &&
                                                      <Box key={applicant.id} data-testId='applicant-list' onClick={() => this.handleApplicant(applicant.id)} sx={webStyles.driverDetails}>
                                                        <Box style={{ display: 'flex', gap: '1.5rem' ,alignItems: 'center'}}>
                                                          <Avatar sx={webStyles.driverPick} alt="#avataricon" src={applicant.attributes?.user_profile_image_url} />
                                                          <Box sx={webStyles.nameContainer}>
                                                            <Typography sx={{...webStyles.fontStyle_7, textTransform:'capitalize'}}>{applicant.attributes.first_name} {applicant.attributes.last_name}</Typography>
                                                            <Typography sx={{ ...webStyles.fontStyle_5, color: '#334155', textTransform:'capitalize' }}>{applicant.attributes.insurance}</Typography>
                                                            <Typography sx={{ ...webStyles.fontStyle_5, color: '#334155', textTransform:'capitalize' }}>{applicant.attributes.authority}</Typography>
                                                          </Box>
                                                        </Box>
                                                        <Box><ExpandMoreRounded /></Box>
                                                      </Box>}
                                                    {this.state.expandedApplicantId === applicant.id && (
                                                      <Box style={webStyles.detailBox1}>
                                                        <div style={{ backgroundColor: '#E2E8F0', borderRadius: '8px', paddingBottom: '35px' }}>
                                                          <Box display={'flex'} justifyContent={'flex-end'} padding={'15px 15px 0 15px'} data-testId='applicant-Detail' onClick={() => this.handleApplicantDetail()} ><ExpandLessRounded /></Box>
                                                          <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Avatar style={{ width: "132px", height: '132px' }} alt="#avataricon" src={applicant.attributes?.user_profile_image_url} />
                                                          </Box>

                                                          <Typography style={{...webStyles.applicantName, textTransform:'capitalize'}}>{applicant.attributes.first_name} {applicant.attributes.last_name}</Typography>
                                                          <Box style={{ ...webStyles.chatBox, textAlign: 'center', margin: '18px 0' }}>
                                                            <Phone style={webStyles.phone1} />
                                                            <Box style={{ ...webStyles.fontStyle_5, color: '#334155' }}>{applicant.attributes.phone_number}</Box>
                                                          </Box>
                                                          <Box style={{ ...webStyles.chatBox, textAlign: 'center', }}>
                                                            <Box sx={{ ...webStyles.chatBoxStyle1, boxShadow: '0px 4px 4px 0px #A77F5161', cursor:'pointer' }} onClick = {()=>this.chatStart(applicant.attributes.first_name, applicant.attributes.last_name,applicant.attributes.trucker_id)} data-testId = "chatStart">
                                                              <ChatBubbleOutlineOutlined />
                                                              <Typography>Chat Now</Typography>
                                                            </Box>
                                                          </Box>
                                                        </div>
                                                        <div>
                                                          <Box style={{ margin: '66px 50px 40px 50px' }}>
                                                            <Box style={{...webStyles.justifySpace, gap:'10px' }}>
                                                              <Box style={{ ...webStyles.flexBox, boxSizing: 'border-box' }}>
                                                                <Typography style={webStyles.flexText}>Insurance</Typography>
                                                                <Typography style={{ ...webStyles.flexText1, textTransform:'capitalize'}}>{applicant.attributes?.insurance}</Typography>
                                                              </Box>
                                                              <Box style={{ ...webStyles.flexBox, boxSizing: 'border-box' }}>
                                                                <Typography style={webStyles.flexText}>Authority</Typography>
                                                                <Typography style={{ ...webStyles.flexText1, textTransform:'capitalize' }}>{applicant.attributes?.authority}</Typography>
                                                              </Box>
                                                            </Box>
                                                          </Box>
                                                          <Box style={{ margin: '0px 50px 84px 50px' }}>
                                                            <Box style={{...webStyles.justifySpace, gap:'10px' }}>
                                                              <Box style={{ ...webStyles.flexBox, boxSizing: 'border-box' }}>
                                                                <Typography style={webStyles.flexText}>Driver License</Typography>
                                                                <Typography style={{ ...webStyles.flexText1, textTransform:'capitalize' }}>{applicant.attributes?.driving_license_number}</Typography>
                                                              </Box>
                                                              <Box style={{ ...webStyles.flexBox, boxSizing: 'border-box' }}>
                                                                <Typography style={webStyles.flexText}>MC Number</Typography>
                                                                <Typography style={{ ...webStyles.flexText1, textTransform:'capitalize' }}>{applicant.attributes?.mc_number}</Typography>
                                                              </Box>
                                                            </Box>
                                                          </Box>
                                                          {this.getButtons(applicant.attributes.status, applicant.attributes.first_name, applicant.attributes.last_name, applicant.id)}
                                                        </div>
                                                      </Box>
                                                    )}
                                                  </>
                                                ))}
                                                {this.state.applicantsList.length === 0 && 
                                                    <Box style={{...webStyles.noApplicant, textAlign: 'center'}}>There is no load applicants details</Box>
                                                }
                                              </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                     
                                    <Box style={{ overflow: "hidden", backgroundColor: '#e9eef2', marginBottom:'40px' }}>
                                        <Accordion style={webStyles.allSelectColor}>
                                            <Box style={{ padding: '20px' }}>
                                                <AccordionSummary expandIcon={<ExpandMoreRounded />}>
                                                    <Typography style={webStyles.allFilterFont}>Route Details</Typography>
                                                </AccordionSummary>
                                            </Box>
                                            <AccordionDetails>
                                              <Box style={{ ...webStyles.coverBox1 }}>
                                                <Box style={{ ...webStyles.routeBox, display:'flex',flexDirection: 'row',boxSizing: 'border-box',marginBottom:'22px' }}>
                                                  <img width={'101px'} height={'62px'} src={truckIcon} />
                                                  <Box style={{display:'flex',flexDirection: 'column' }}>
                                                    <Typography style={webStyles.fontStyle_9}>Status</Typography>
                                                    <Typography style={{...webStyles.fontStyle_6, fontSize:'18px', textTransform:'capitalize'}}>{(selectedCard.attributes.status).replace('_', ' ')}</Typography>
                                                  </Box>
                                                </Box>
                                                <Box style={{ ...webStyles.routeBox, display:'flex',justifyContent:'space-between',boxSizing: 'border-box' }}>
                                                  
                                                  <Box style={{display:'flex',flexDirection: 'column' }}>
                                                    <Typography style={{...webStyles.fontStyle_9, marginBottom:'20px'}}>Live Location</Typography>
                                                    <Typography style={{...webStyles.fontStyle_6, fontSize:'18px', textTransform:'capitalize'}}>822 E. 20th Street,</Typography>
                                                    <Typography style={{...webStyles.fontStyle_6, fontSize:'18px', textTransform:'capitalize'}}>Los Angeles,</Typography>
                                                    <Typography style={{...webStyles.fontStyle_6, fontSize:'18px', textTransform:'capitalize'}}>California</Typography>
                                                    <Typography style={{...webStyles.fontStyle_6, fontSize:'18px', textTransform:'capitalize'}}>90011</Typography>
                                                  </Box>
                                                  <Box style={webStyles.locationBox}><LocationOnOutlined sx={{color:'#fff', margin: '18px'}}/></Box>
                                                </Box>
                                              </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                        
                                    </Box>

                                    <Box style={{ overflow: "hidden", backgroundColor: '#e9eef2', marginBottom:'40px' }}>
                                        <Accordion style={webStyles.allSelectColor}>
                                            <Box style={{ padding: '20px' }}>
                                                <AccordionSummary expandIcon={<ExpandMoreRounded />}>
                                                    <Typography style={webStyles.allFilterFont}>Shipment Details</Typography>
                                                </AccordionSummary>
                                            </Box>
                                            <AccordionDetails>
                                              <Box style={{ ...webStyles.coverBox, flexDirection: 'column' }}>
                                                <Box display={'flex'} flexDirection={'column'} gap={'1rem'} p={{xs:0, sm:'0px', md:'30px'}}>
                                                  <Box style={webStyles.displayFlexBox}>
                                                    <Typography sx={{...webStyles.fontStyle_9, minWidth:'130px'}}>{configJSON.startDate}</Typography>
                                                    <Typography sx={{ ...webStyles.fontStyle_8, fontSize: '18px !important' }}>{selectedCard?.attributes.start_date}</Typography>
                                                  </Box>
                                                  <Divider sx={webStyles.dividerStyles} />

                                                  <Box style={webStyles.displayFlexBox}>
                                                    <Typography sx={{...webStyles.fontStyle_9, minWidth:'130px'}}>{configJSON.endDate}</Typography>
                                                    <Typography sx={{ ...webStyles.fontStyle_8, fontSize: '18px !important' }}>{selectedCard?.attributes.end_date}</Typography>
                                                  </Box>
                                                  <Divider sx={webStyles.dividerStyles} />

                                                  <Box style={webStyles.displayFlexBox}>
                                                    <Typography sx={{...webStyles.fontStyle_9, minWidth:'130px'}}>{configJSON.comodity}</Typography>
                                                    <Typography sx={{ ...webStyles.fontStyle_8, fontSize: '18px !important' }}>{'Goods'}</Typography>
                                                  </Box>
                                                  <Divider sx={webStyles.dividerStyles} />

                                                  <Box style={webStyles.displayFlexBox}>
                                                    <Typography sx={{...webStyles.fontStyle_9, minWidth:'130px'}}>{configJSON.totalMiles}</Typography>
                                                    <Typography sx={{ ...webStyles.fontStyle_8, fontSize: '18px !important' }}>{this.formatLoadMiles(selectedCard?.attributes.total_miles)}</Typography>
                                                  </Box>
                                                  <Divider sx={webStyles.dividerStyles} />

                                                  <Box style={webStyles.displayFlexBox}>
                                                    <Typography sx={{ ...{...webStyles.fontStyle_9, minWidth:'130px'} }}>
                                                      {configJSON.originAddress.split(' ')[0]}
                                                      <br />
                                                      {configJSON.originAddress.split(' ')[1]}
                                                    </Typography>
                                                    <Typography sx={{ ...webStyles.fontStyle_8, fontSize: '18px !important' }}>
                                                      {selectedCard?.attributes.origin_address.address}<br />
                                                      {selectedCard?.attributes.origin_address?.city}, {selectedCard?.attributes.origin_address?.state} <br />
                                                      {selectedCard?.attributes.origin_address?.country} - {selectedCard?.attributes.origin_address?.zip_code}
                                                    </Typography>
                                                  </Box>
                                                  <Divider sx={webStyles.dividerStyles} />

                                                  <Box style={webStyles.displayFlexBox}>
                                                    <Typography sx={{ ...{...webStyles.fontStyle_9, minWidth:'130px'} }}>
                                                      {configJSON.destinationAddress.split(' ')[0]}
                                                      <br />
                                                      {configJSON.destinationAddress.split(' ')[1]}
                                                    </Typography>
                                                    <Typography sx={{ ...webStyles.fontStyle_8, fontSize: '18px !important' }}>
                                                      {selectedCard?.attributes.destination_address?.address} <br />
                                                      {selectedCard?.attributes.destination_address?.city}, {selectedCard?.attributes.destination_address?.state} <br />
                                                      {selectedCard?.attributes.destination_address?.country} - {selectedCard?.attributes.destination_address?.zip_code}
                                                    </Typography>
                                                  </Box>
                                                  <Divider sx={webStyles.dividerStyles} />

                                                  <Box style={webStyles.displayFlexBox}>
                                                    <Typography sx={{...webStyles.fontStyle_9, minWidth:'130px'}}>{configJSON.loadDetails}</Typography>
                                                    <Typography sx={{ ...webStyles.fontStyle_8, fontSize: '18px !important' }}>{selectedCard?.attributes.load_details || 'NA'}</Typography>
                                                  </Box>
                                                </Box>
                                              </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                    <Box>
                                    </Box>
                                  </Box>
                                </Box>
                              )}
                              </Grid>
                            </Grid> :
                            (this.state.loadsDataList.length === 0 && 
                              <Box style={{...webStyles.noApplicant, fontSize:'30px',textAlign: 'center', fontWeight: 500, opacity: 0.5}}>There is no loads available with this status</Box>
                            )}                            
                            {this.showSuccMessage()}
                        </Box>
                    </Box>
                    </ThemeProvider>
                </Box>
            </Box >
            {this.renderApproveDialog()}
            {this.renderRejectDialog()}
            {this.renderDeleteDialog()}
            </>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  '& .MuiStepConnector-line': {
    borderColor: '#BAC9DA',
    borderLeftWidth: 2,
    margin: '-7px 0 -7px -8px',
  },
}));

const webStyles = {
  clearButton: {
    float: "right",
    background: "red",
    borderRadius: "50%",
    height: "40px",
    width: "40px",
  },
  imagePreview:{
    position:"absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
    width:"40%",
    maxWidth:500,
    zIndex:10,
  },
  imageStyle:{
    width: '200px', 
    borderRadius: '8px', 
    zIndex: 10, 
    marginBottom:5
  },
  dialogStyle: {
    padding: '20px 40px',
    borderRadius: '8px',
    width: '30%',
    backgroundColor: '#FFFFFF',
    boxShadow: 'box-shadow: 0px 8px 32px 0px #0000000F'
  },
  noApplicant: { fontSize:'20px', color: '#465567',fontFamily: "Inter",},
  displayFlexBox:{display:'flex', justifyContent: 'space-between'},
  locationBox:{backgroundColor:'#F19F40', height:'67px', width:'67px', borderRadius:'50%', marginRight:'20px'},
  routeBox:{borderLeft: "23px solid #C17F33", boxShadow:'0px 4px 15px 0px #00000020', padding: '16px 0 16px 16px', gap:'15px',minWidth: '50%', borderRadius: '10px', border: '0px, 0px, 0px, 4px', backgroundColor: '#FFFFFF' },
  fontStyle_8: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    color: "#1C1917",
    textAlign: 'Left',
    width: '350px',
    [theme.breakpoints.down('sm')]: { 
      width: '150px'
    },
    [theme.breakpoints.down('xs')]: { 
      width: '150px'
    },
    [theme.breakpoints.down('md')]: {
      width: '150px'
    },
    minWidth:'150px'
  },
  fontStyle_9: {
      fontFamily: "Inter",
      fontSize: "18px",
      fontWeight: 400,
      color: "#475569"
  },
  dividerStyles: {
    borderStyle: 'dotted',
    borderWidth: '1px',
    borderColor: '#BAC9DA',
  },
  cancelButton: {
    height: '56px', width: '170px',
    color: '#B57730',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    backgroundColor: '#FDF1E2',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#FDF1E2',
    }
  },
  LogoutButton: {
    height: '56px', width: '170px',
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    cursor: 'pointer',
    background: 'linear-gradient(99.09deg, #915F26 2.64%, #F19F40 100%)',
    '&:hover': {
      background: 'linear-gradient(99.09deg, #915F26 2.64%, #F19F40 100%)',
    }
  },
  justifySpace:{display: 'flex', justifyContent: 'space-between'},
  flexText1:{ color: '#0F172A', fontSize: '14px', fontFamily: 'text-3xl/font-bold', fontWeight: 400, marginTop: "10px",},
  flexText:{ color: '#0F172A', fontSize: '14px', fontFamily: 'text-3xl/font-bold', fontWeight: 700, },
  flexBox:{borderLeft: "4px solid #C17F33", boxShadow:'0px 4px 15px 0px #00000020', padding: '16px 0 0 16px', height: '110px', width: '170px', borderRadius: '6px', border: '0px, 0px, 0px, 4px', backgroundColor: '#FFFFFF' },
  phone1:{width:'22px', height:'22px', color: '#334155'},
  chatBox:{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center' },
  applicantName:{display: 'flex', justifyContent: 'center', color: '#0F172A', fontSize: '30px', fontFamily: 'text-3xl/font-bold', fontWeight: 700, marginTop: '21px' },
  detailBox1:{ height: '100%',},
  chatBoxStyle1: {
    width: '130px',
    height: '40px',
    backgroundColor: '#FBE1C4',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
    color:'#B57730'
  },
  accordianText:{
    backgroundColor: '#475569',
  },
  coverBox:{ padding: '30px',backgroundColor: '#FFF',display:'flex', gap:'40px' },
  coverBox2: { padding: '30px',backgroundColor: '#FFF',display:'flex', gap:'40px', paddingRight:'20px',
    '::-webkit-scrollbar': {
      width: '12px',
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: '#F9FAFB',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#DEE5ED',
      borderRadius: '6px',
    },
    '*': {
      scrollbarWidth: 'thin', 
      scrollbarColor: '#DEE5ED #F9FAFB',
    },
  },
  coverBox1: { padding: '30px',backgroundColor: '#FFF' },
  selectStyle:{
    fontFamily: 'Inter',
    marginLeft: '-10px',
    color: '#475569',
    opacity: 0.9,
    fontSize: '16px',
    fontWeight:400
  },
  input: {
    height: '56px',
    radius: '8px',
    padding: '20px',
    marginLeft: '-10px',
    width: '327px',
  },
  allSelectColor: { backgroundColor: '#e9eef2', borderRadius: '0px'},
  allFilterFont: { color: '#0F172A', cursor: "pointer", fontSize: '24px', fontWeight: 400, fontFamily: "Inter" },
  driverDetails: {
    backgroundColor: '#E2E8F0',
    padding: '10px 16px',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    height:'120px',
    justifyContent: 'space-between',

  },
  driverPick: {
    width: '70px',
    height: '70px'
  },
  nameContainer: {
    flexDirection: 'column',
    display: 'flex',
    gap: '5px',
  },
  fontStyle_7: {
    fontFamily: "Inter",
    fontSize: '22px',
    fontWeight: 800,
    color: "#0F172A"
  },
  fontStyle_5: {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: "Inter",
    color: "#0F172A"
  },
  fontStyle_6: {
    fontWeight: 700,
    fontFamily: "Inter",
    color: "#1C1917",
    fontSize: '16px',
  },
  boxColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },
  trackingDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: '7px',
  },
  driverInfoCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px'
  },
  mainTruckBox:{
    backgroundColor: '#e9eef2',
    borderRadius: '16px',
    marginBottom: '50px',
  },
  trackingBoxStyle: {
    display: 'flex',
    alignItems: 'center',
    padding: '50px 50px 0px 50px',
  },
  infoBoxPadding: {    
    marginTop: '50px',
    borderRadius: '16px',
    border:'2px solid #e9eef2',
    boxShadow: '0px 4px 4px 0px #00000040',
  },
  priceText:{fontWeight: 700, fontSize: '36px', color: '#475569'},
  buttonStyle: {
    color: "#a87f51",
    backgroundColor:'#FBE1C4',
    borderRadius: '20px',
    padding: '5px 20px',
    height:'40px',
    fontSize: '16px',
    fontWeight: 700,
    alignItems: 'center',
  },
  viewBox: {
    backgroundColor: '#915F26',
    borderRadius: '0px 0px 13px 13px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 20px',
  },
  dateBox: {
    position: 'relative',
    right: '70px',
    top: '40px'
  },
  dateFontSyle_1: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#475569',
    fontFamily: "Inter"
  },
  dateFontSyle_2: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#BDBDBD',
    fontFamily: "Inter"
  },
  statusBox: {
    fontFamily: "Inter",
  },
  fontSyle_3: {
    fontFamily: "Inter",
    fontSize: '16px',
    fontWeight: 700,
    color: "#FFFFFF",
    backgroundColor: '#215089',
    borderRadius: '3px 0px 0px 3px',
    padding: '5px 20px',
    marginRight:'-20px',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  fontSyle_4: {
    fontFamily: "Inter",
    fontSize: '18px',
    fontWeight: 400,
    color: "#0F172A"
  },
  fontSyle_5: {
    fontFamily: "Inter",
    fontSize: '18px',
    fontWeight: 400,
    color: "#FFFFFF",
  },
  headerFont: {
    fontWeight: 700,
    fontSize: '30px',
    fontFamily: "Inter",
    color: "#215089"
  },
  boxPadding: {
    padding: '20px 20px 10px 20px'
  },
  cardHeader: {
    padding: '20px 20px 0px 20px',
  },
  content: {
    display: "flex",
    flex: 1,
  },
  mainContent: {
    flex: 1,
    padding: "2rem",
    backgroundColor: '#E2E8F0',
  },
  container: {
    flexDirection: "column",
    display: "flex",
    overflowX: 'hidden'
  },
  tabContainer: {
    backgroundColor: '#FFFFFF',
    border: '2px solid #215089',
    borderRadius: '16px',
    padding: '2rem',
  },
  styledCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    overflowY:'auto',
    maxHeight:'1650px',
    paddingRight:'20px',
    marginTop: '50px',
    '::-webkit-scrollbar': {
      width: '12px',
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: '#F9FAFB',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#DEE5ED',
      borderRadius: '6px',
    },
    '*': {
      scrollbarWidth: 'thin', 
      scrollbarColor: '#DEE5ED #F9FAFB',
    },
  },
  cardStyle: {
    marginBottom: '50px',
    border: '1px solid #FBE1C4',
    cursor: 'pointer',
    borderRadius: '16px',
  },
};
// Customizable Area End