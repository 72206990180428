import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { FormikProps } from "formik";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { SelectChangeEvent } from '@mui/material/Select';

interface ApiResponse {
  errors?: {
    mobile_number?: string;
    email?: string;
  }[];
  data?: {
    id: string;
    type: string;
    attributes: {
      user_type: string;
      first_name: string;
      last_name: string;
      email: string;
      mobile_number: string;
      activated: boolean;
    };
  };
  meta?: {
    token: string;
  };
  message?: string;
  token?: string;
}

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}
interface FormValues {
  selectUserType: string;
  firstname: string;
  lastname: string;
  email: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
  termAndConAgree: boolean;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  width: number;
  enablePasswordField: boolean;
  enableConfirmPasswordField: boolean;
  errorMessage: string;
  email:string;
  showModal:boolean;
  isDisable:boolean;
  userType: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createAccountLoginId: string = "";
  registerFormProps?: FormikProps<{
    selectUserType: string;
    lastname: string;
    firstname: string;
    email: string;
    phoneNumber: string;
    password: string;
    confirmPassword: string;
    termAndConAgree: boolean;
  }> = undefined;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.state = {
      width: window.innerWidth,
      enablePasswordField: true,
      enableConfirmPasswordField: true,
      errorMessage: "",
      email:'',
      showModal:false,
      isDisable:false,
      userType:''
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    window.addEventListener("resize", () => this.handleWidthChange());
    // Customizable Area End
  }

  // Customizable Area Start
  handleWidthChange = () => {
    this.setState({ width: window.innerWidth });
  };

  updateRegisterFormProps = (
    props: FormikProps<{
      selectUserType: string;
      firstname:string;
      lastname: string;
      email: string;
      phoneNumber: string;
      password: string;
      confirmPassword: string;
      termAndConAgree: boolean;
    }>
  ) => {
    this.registerFormProps = props;
  };

  handleSuccess = (response:any) => {
    if(response){
      this.handleSignUp(response)

    }
  };

  handleError = () => {};
  handleTogglePassword = () =>
    this.setState({ enablePasswordField: !this.state.enablePasswordField });

  handleToggleConfirmPassword = () =>
    this.setState({
      enableConfirmPasswordField: !this.state.enableConfirmPasswordField,
    });

  goToTermsAndConditions = () => {
    this.setState({showModal:true})
  };
  handleOpenModal = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handleAccept = () => {
    if (this.registerFormProps) {
      this.registerFormProps.setFieldValue('termAndConAgree', true);
    }
    this.setState({ showModal: false,isDisable:false });
  };

  goToLogin = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock");
  };

  handleSignUp = (values: any) => {
   
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    this.setState({email:values.email})
    const attrs = {
      first_name: values.firstname,
      last_name: values.lastname,
      user_type: values.selectUserType,
      email: values.email,
      mobile_number: values.phoneNumber,
      password: values.password,
      password_confirmation: values.confirmPassword,
      terms_and_condition_accepted: values.termAndConAgree
    };
    const data2  = values.access_token 
    ?
    {
      type:'google_account',
      id_token:values.access_token
    }:
    {
      type: "email_account",
      attributes: attrs,
      
    };

    const httpBody = {
      data: data2,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountLoginId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleCreateAccount(response: ApiResponse) {
    if (response.errors) {
      response.errors.forEach((error) => {
        if (error.email) {
          this.setState({ errorMessage: `email ${error.email}` });
        }
        if (error.mobile_number) {
          this.setState({ errorMessage: error.mobile_number });
        }
      });
    }
    else if (response.message && response.token) {
      localStorage.setItem("LoginToken", response.token)
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }
    else if (response.meta && response.data) {
      localStorage.setItem('LoginToken', response.meta?.token)
      localStorage.setItem("LoginId",response.data?.id)
      localStorage.setItem("LoggedInUserType",response.data.attributes.user_type)
    
      const msg: Message = new Message(
        getName(MessageEnum.SignupToOtp)
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      msg.addData(getName(MessageEnum.NavigatingEmail), this.state.email)
      this.send(msg);
    }
  }


  handleChange = (field:string, props:FormikProps<FormValues>) => (e:React.ChangeEvent<HTMLInputElement>) => {
    props.setFieldValue(field, e.target.value);
    this.setState({ errorMessage: '' });
  };
  
  handleSelectChange = (field:string, props:FormikProps<FormValues>) => (e:SelectChangeEvent<string>) => {
    props.setFieldValue(field, e.target.value);
    this.setState({ errorMessage: '' });
  };
  
  getFieldError = (field:keyof FormValues, props:FormikProps<FormValues>) =>
    props.touched[field] && !!props.errors[field]
      ? props.errors[field]
      : "";

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.createAccountLoginId) {
      this.handleCreateAccount(responseJson);
    }
    // Customizable Area End
  }
}
