import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
} from "@mui/material";
import {
  styled
} from "@mui/material/styles";
import { ChatContainer, 
  MessageList, 
  MessageInput, 
  ConversationHeader, 
  Avatar, 
  Message, 
  MainContainer, 
  Sidebar, 
  Search, 
  ConversationList, 
  Conversation} from '@chatscope/chat-ui-kit-react';
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import HeaderNavigation from "../../navigationmenu/src/HeaderNavigation.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { MoreVert, Clear } from "@mui/icons-material";
import Loader from "../../../components/src/Loader.web";
import {IMessageList} from "./ChatController"

const MainContainerBox = styled(Box)(({ theme })=> ({
  display: "flex",
  flexDirection: "column",
  overflow: 'hidden',
  height: "100vh",
  "*":{
    fontFamily:"inter"
  },
  ".cs-conversation" : {
    marginTop:25,
    // alignItems:"center",
    "& .cs-avatar":{
    height:91,
    width:91
  }},
  ".cs-status--available .cs-status__bullet":{
     background:"#80EA4E"
  },
  ".cs-conversation__name":{
    fontSize:18,
    color:"#1E293B",
    fontWeight:700,
    marginBottom:10,
  },
  ".cs-conversation__info-content":{
    color:"#78716C",
    fontSize:16,
    lineHeight:2
  },
  ".cs-conversation__unread": {
    backgroundColor: "#F19F40", 
    color: "#ffffff",
    borderRadius: "50%",
    height:"41px",
    width:"41px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    top:"3.5rem",
    right:0,
    fontSize:20,
    fontWeight:700,
    fontFamily:"inter"
  },
  ".cs-conversation__info": {
    whiteSpace: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    '-webkit-line-clamp': "2!important", 
    "line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
  ".cs-main-container > .cs-sidebar.cs-sidebar--left":{
    maxWidth:"547px",
    width:"45%",
    border:0,
    flexBasis:"45%"
  },
  ".cs-conversation__last-activity-time":{
    alignItems:"flex-start",
    marginTop:10
  },
  ".cs-conversation__content":{
    flexGrow:0,
    width:"40%",
    justifyContent:"center",
  },
  ".cs-conversation-header":{
    background:"#215089",
    borderRadius:25,
    padding:"30px"
  },
  ".cs-conversation-header__actions":{
    color:"#ffffff",
    ".css-i4bv87-MuiSvgIcon-root":{
      fontSize:"2.5rem"
    }
  },
  ".cs-conversation-header__content":{
    justifyContent:"space-around"
  },
  ".cs-conversation-header__avatar, .cs-avatar.cs-avatar--md":{
    height:90,
    width:88
  },
  ".cs-conversation-header__user-name":{
    background:"#215089",
    color:"#FFFFFF",
    fontSize:24,
    fontWeight:700,
    textTransform:"uppercase",
    maxWidth:"260px"
  },
  ".cs-conversation-header__info":{
    background:"#215089",
    color:"#FFFFFF",
    fontSize:16,
    fontWeight:400
  },
  ".cs-main-container":{
    border:0,
    height:"100%!important"
  },
  ".cs-chat-container":{
    border:"1px solid #DEE5ED",
    borderRadius:24,
    height:"auto",
    marginLeft:25,
    flexBasis:"55%"
  },
  ".cs-message--incoming .cs-message__content": {
    backgroundColor: "#E9EEF3",
    padding:"30px 20px",
    fontSize:18,
    marginTop:20
  },
  ".cs-message--outgoing .cs-message__content":{
    backgroundColor: "#BAC9DA",
    padding:"30px 20px",
    fontSize:18,
    marginTop:20
  },
  ".cs-message-input":{
    borderTop:0,
    boxShadow:"4px 4px 0px 0 #00000033",
    borderRadius:36,
    margin:20,
    marginBottom:30 
  },
  ".cs-message-input__content-editor-wrapper,.cs-message-input__content-editor":{
    backgroundColor:"#fff"
  },
  ".cs-button--attachment, .cs-button--send": {
    color: "#A8A29E",
    alignSelf:"center"
  },
  ".cs-search":{
    backgroundColor:"#fff",
    borderRadius:4,
    border:"1px solid #CBD5E1",
    maxWidth:344,
  },
  ".cs-search__input":{
    backgroundColor:"#fff",
    "& ::placeholder":{
      color:"#94A3B8",
      fontWeight:400,
    }
  },
  ".cs-search__search-icon":{
    color:"#94A3B8"
  },
  ".cs-message-input__tools:last-child":{
    transform:"rotate(60deg)",
    ".svg-inline--fa, .fa-paper-plane":{
      color:"black"
    }
  },
  ".cs-message":{
    maxWidth:"70%"
  }
})) ;
// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSideBar = () => {
    return (
      <Sidebar
        position="left"
        style={{ height: '100%', overflowY: 'auto' }} // Ensure the sidebar can scroll
        scrollable={true}
      >
        <Search
          data-test-id="search-bar"
          value={this.state.searchUser}
          onChange={this.handleChangeSearch}
          placeholder="Search" />

        <ConversationList>
          {this.state.userList.map((userList, index) => {
            
            const senderInfo = +this.loginId === userList.attributes.sender_id
              ? userList.attributes.receiver_account.data.attributes
              : userList.attributes.sender_account.data.attributes;

            const onlineStatus = userList.attributes.other_user_is_online ? "available" : "invisible"
            
            return (
              <Conversation
                key={index}
                info={userList.attributes.latest_message_content}
                name={`${senderInfo.first_name} ${senderInfo.last_name}`
                }
                lastActivityTime={<span style={{
                  color: "#94A3B8",
                  fontSize: 18
                }}>{this.formatMessageTime(userList.attributes.latest_message_created_at)}</span>}
                unreadCnt={userList.attributes.unread_count}
                onClick={() => this.onSelectChatBox(userList.id)}
                data-test-id="chat_Box"
              >
                 {senderInfo.user_profile_image ? <Avatar
                  size="fluid"
                  style={{backgroundColor:"lightGray"}}
                  src={senderInfo.user_profile_image}
                  status={onlineStatus}
                /> : (
                  <Avatar
                    size="fluid"
                    status={onlineStatus}
                    children={<>
                      <Typography>{senderInfo.first_name.charAt(0)}</Typography>
                      <div className={`cs-status cs-status--fluid cs-status--${onlineStatus}`}>
                        <div className="cs-status__bullet"></div>
                      </div>
                    </>}
                    style={webStyles.nameAvatar}/>
                )}
              </Conversation>
            )
          })}
        </ConversationList>

      </Sidebar>
    )
  }

  renderChatContainer = () => {
    const { perticularUserChat } = this.state
    const { messages } = perticularUserChat;
    const onlineStatus = perticularUserChat.other_user_is_online ? "available" : "invisible";
    return (
      <ChatContainer>
        <ConversationHeader>
          <ConversationHeader.Back />
        { perticularUserChat.user_profile_image? <Avatar
            size="fluid"
            style={{backgroundColor:"lightGray"}}
            src={perticularUserChat.user_profile_image}
            status={onlineStatus}
          /> : <Avatar
            status={onlineStatus}
            size="fluid"
            style={webStyles.nameAvatar}
            children={<>
              <Typography>{perticularUserChat.first_name.charAt(0)}</Typography>
              <div className={`cs-status cs-status--fluid cs-status--${onlineStatus}`}>
                <div className="cs-status__bullet"></div>
              </div>
            </>}
            />} 
          <ConversationHeader.Content
            info={perticularUserChat.other_user_is_online ? "Active Now" : "Away"}
            userName={`${perticularUserChat.first_name.substring(0, 10)} ${perticularUserChat.last_name}`}
          />
          <ConversationHeader.Actions>
            <MoreVert />
          </ConversationHeader.Actions>
        </ConversationHeader>
        <MessageList>
          {messages.map((mList: IMessageList) => (
            <Box sx={{
                display:"flex", 
                alignItems:"center", 
                flexDirection:mList.attributes.account_id === +this.loginId ? "row-reverse": "row",
                padding:"0 30px",
              }}>
            <Message
              key={mList.id}
              model={{
                direction: mList.attributes.account_id === +this.loginId ? "outgoing" : "incoming",
                message: mList.attributes.message,
                position: 'single',
                sender: 'Zoe',
                sentTime: mList.attributes.created_at
              }}

            ><Message.CustomContent >
                {mList.attributes.attachments ? (
                  mList.attributes.attachments.map((attach) => (
                    <Box sx={{display:"flex", flexDirection:"column"}}>
                      <img src={attach.url} alt="file-uploaded" style={webStyles.imageStyle} />
                      <span>{mList.attributes.message}</span>
                    </Box>
                  ))) : <span>{mList.attributes.message}</span>}
              </Message.CustomContent>
            </Message>
            <Typography sx={webStyles.timeStyle}>{this.formatDate(mList.attributes.created_at,'hh:mm A')}</Typography>
            </Box>
          ))}
        </MessageList>

        <MessageInput
          placeholder="Type a message"
          data-test-id="message_input"
          onSend={() => this.handleOnSendMessage()}
          onChange={(innerHtml, textContent, innerText) => this.handleOnChange(innerHtml, textContent, innerText)}
          onAttachClick={this.handleOnAttachClick}
        />
      </ChatContainer>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    
    const {fileUploaded} = this.state;
    
    return (
      <MainContainerBox data-data-id="chat-main-container">
        <HeaderNavigation navigation={this.props.navigation} id={""} />
        <Box sx={webStyles.content}>
          <NavigationMenu navigation={this.props.navigation} id={""} />
          <Box sx={webStyles.mainContent}>

        {this.state.isLoading ? <Loader loading={this.state.isLoading} />
            :<Box sx={webStyles.cardContainer}>
              <Typography style={webStyles.headerFont}>{configJSON.labelTitleText}</Typography>
              <Box sx={webStyles.cardBox}>
                <MainContainer
                  responsive
                  style={{
                    height: '600px',
                    position:"relative"
                    }}
                  >
                    {this.state.userList.length > 0 
                      ? <>{this.renderSideBar()}

                    {this.renderChatContainer()}
                      </> : <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", width:"100%"}}>
                        <Typography sx={webStyles.noApplicant}>No messages available at the moment.</Typography>
                      </Box>}
                    {fileUploaded?.type?.startsWith("image/") ? (
                      <Box sx={webStyles.imagePreview}>
                        <Clear onClick={() => this.handleOnClear()} sx={webStyles.clearButton} />
                        <img src={URL.createObjectURL(fileUploaded)} style={{ borderRadius: '8px', width: "100%" }} />
                        {this.state.inputMessage === "" && <Typography sx={{color:"red"}}>Please add message with it.</Typography>}
                      </Box>
                    ) : <></>}

                  </MainContainer>
              </Box>
            </Box>
  }
          </Box>
        </Box>
      </MainContainerBox>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    overflowX: 'hidden',
    height:"100vh"
  },
  content: {
    display: "flex",
    flex: 1,
  },
  mainContent: {
    flex: 1,
    padding: "2rem",
    backgroundColor: '#E2E8F0',
  },
  cardContainer: {
    backgroundColor: '#FFFFFF',
    border: '2px solid #215089',
    borderRadius: '16px',
    padding: '2rem',
    height:"80vh"
  },
  headerFont: {
    fontFamily: "Inter",
    fontSize: '30px',
    fontWeight: 700,
    color: "#215089"
  },
  cardBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    marginTop: '2%',
    marginBottom: '5%',
    height:"90%"
  },
  imagePreview:{
    position:"absolute",
    left: "50%",
    bottom: "12%",
    width:"40%",
    maxWidth:250,
    zIndex:10,
  },
  clearButton: {
    float: "right",
    background: "red",
    borderRadius: "50%",
    height: "40px",
    width: "40px",
  },
  imageStyle:{
    width: '200px', 
    borderRadius: '8px', 
    zIndex: 10, 
    marginBottom:5
  },
  timeStyle:{
    fontWeight:400,
    fontSize:16,
  },
  noApplicant: { 
    fontSize:'20px', 
    color: '#465567',
    fontFamily: "Inter",
    textAlign:"center"
  },
  nameAvatar:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    borderRadius:"50%",
    background:"lightgray"
  }
};
// Customizable Area End
