import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start

interface IUserList {
  "id": string,
  "type": string,
  "attributes": {
    "twilio_channel_sid": string,
    "sender_id": number,
    "receiver_id": number,
    "latest_message_id": number,
    "latest_message_content": string,
    "latest_message_created_at": string,
    "sender_account": {
      "data": {
        "attributes": {
          "activated": boolean,
          "first_name": string,
          "last_name": string,
          "user_profile_image": string 
        }
      }
    },
    "receiver_account": {
      "data": {
        "attributes": {
          "activated": boolean,
          "first_name": string,
          "last_name": string,
          "user_profile_image": string 
        }
      }
    },
    "unread_count": number,
    "other_user_is_online":boolean,
  }
}

interface IPerticularMessages {
  "id": string,
  "twilio_channel_sid": string,
  "sender_id": number,
  "receiver_id": number,
  "other_user_is_online": boolean,
  "unread_count": boolean,
  "messages": IMessageList[],
  "user_profile_image":string,
  "first_name":string,
  "last_name": string,
  }

export interface IMessageList {
  "id": string,
  "attributes": {
    "message": string,
    "account_id": number,
    "created_at": string,
    "is_mark_read": boolean,
    "attachments": null | {
      url:string,
      id:string,
    }[]
  }
}

  interface MessageType {
  author: string;
  text: string;
}
export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
}

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  accountId: number;
  chatName: string;
  chatList: IChat[];
  isVisibleModal: boolean;
  searchUser:string,
  userToken:string,
  messages: MessageType[];
  userList: IUserList [];
  isLoading:boolean;
  inputMessage:string;
  perticularUserChat: IPerticularMessages;
  selectedChatId:string|null;
  fileUploaded: Blob,
  attachmentError:string
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatListApiCallId: string = "";
  createChatRoomApiCallId: string = "";
  getListOfUsersAPIcall:string = "";
  getPerticularUsersChatAPIcall:string = "";
  getSearchUserApiCall:string ="";
  sendMessageAPICall:string = "";
  loginId:string = "";
  intervalId: NodeJS.Timeout | null
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      chatName: "",
      chatList: [],
      isVisibleModal: false,
      searchUser:"",
      userToken:"",
      messages: [],
      userList:[],
      isLoading:true,
      inputMessage:"",
      perticularUserChat:{} as IPerticularMessages,
      selectedChatId:null,
      fileUploaded: {} as Blob,
      attachmentError:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.intervalId = null
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.loginId = await getStorageData("LoginId");
    this.getListOfUsers();
    this.intervalId = setInterval(this.getListOfUsers, 60000)
  }

  async componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    };
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  isStringNullOrBlank = (string: string) => {
    return string === undefined || string === null || string.length === 0;
  };

  showModal = () => {
    this.setState({ isVisibleModal: true });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  navigateToChatView = (chatId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ViewChat");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      chatId: chatId,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  getChatList = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyChatsApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createChatRoom = (chatName: string) => {
    if (this.isStringNullOrBlank(chatName)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
        "Access-Control-Allow-Origin": "*",
      };
      const bodyData = {
        name: chatName,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createChatRoomApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createChatRoomApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  inputRoomNameProps = {
    onChangeText: (chatName: string) => {
      this.setState({ chatName });
    },
  };

  btnAddRoomProps = {
    onPress: () => this.createChatRoom(this.state.chatName),
  };

  btnCloseModalProps = {
    onPress: () => this.hideModal(),
  };

  btnShowAddModalProps = {
    onPress: () => {
      this.showModal();
    },
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if(responseJson){
      switch (apiRequestCallId){

        case this.getListOfUsersAPIcall:
          this.setState({ userList: responseJson.data })
          responseJson.data.length > 0 
          ? this.getPerticularUsersChat(this.state.selectedChatId ?? responseJson.data[0].id)
          : this.setState({isLoading:false})
          break;

        case this.getPerticularUsersChatAPIcall:
          const senderInfo = +this.loginId === responseJson.data.attributes.sender_id 
          ? responseJson.data.attributes.receiver_account.data.attributes
          : responseJson.data.attributes.sender_account.data.attributes;
          const responseData = {
            "id": responseJson.data.id,
            "twilio_channel_sid": responseJson.data.attributes.twilio_channel_sid,
            "sender_id": responseJson.data.attributes.sender_id,
            "receiver_id": responseJson.data.attributes.receiver_id,
            "other_user_is_online": responseJson.data.attributes.other_user_is_online,
            "unread_count": responseJson.data.attributes.unread_count,
            "messages": responseJson.data.attributes.messages.data,
            "user_profile_image":senderInfo.user_profile_image,
            "first_name":senderInfo.first_name,
            "last_name":senderInfo.last_name
          }
          
          this.setState({perticularUserChat: responseData, isLoading:false})
          break;

        case this.getSearchUserApiCall:

          this.setState({ userList: responseJson.chats.data })
          break;

        case this.sendMessageAPICall:
          this.setState({fileUploaded : {} as Blob})
          this.getListOfUsers()
      }
    }
  }

  handleChangeSearch = (value:string) => {
    this.setState({searchUser:value});
    this.getSearchUser(value);
  }

  getSearchUser = async(name:string) => {
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: await getStorageData("LoginToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSearchUserApiCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.searchUserApiEndPoint}${name}`);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getListOfUsers = async () => {
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: await getStorageData("LoginToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getListOfUsersAPIcall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getListOfUsersAPIEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleOnSendMessage = async() =>{
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: await getStorageData("LoginToken")
    };

    const formData = new FormData();

    formData.append("data[message]", this.state.inputMessage.trim());
    formData.append("twilio_channel_sid", this.state.perticularUserChat.twilio_channel_sid);
    this.state.fileUploaded.type && formData.append("data[attachments][]", this.state.fileUploaded as Blob)
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendMessageAPICall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postSendMessageApiEndPoint}`);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleOnChange = (innerHtml: string, textContent: string, innerText: string) => {
    this.setState({
      inputMessage:innerText,
      attachmentError:""
    })
  }

  formatMessageTime(timestamp: string) {
    const messageTime = moment(timestamp);
    const now = moment();

    const diffInMinutes = now.diff(messageTime, 'minutes');
    const diffInHours = now.diff(messageTime, 'hours');

    if (diffInMinutes < 60) {
      return `${diffInMinutes} min ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
    } else {
      return messageTime.format('ddd'); // Returns the day (e.g., "Mon")
    }
  }

  handleOnAttachClick = () => {
    const input = document.createElement('input');
    input.setAttribute("data-test-id","file_input")
    input.accept = 'image/*,application/pdf'; 
    input.type = 'file';
    input.onchange = (event) => {
      const target = event.target as HTMLInputElement; // Assert the target as HTMLInputElement
      const file = target.files?.[0];
      if (file) {
        this.handleFileUpload(file);
      }
    };
    input.click();
  }

  handleFileUpload = (file:File) => this.setState({fileUploaded : file});

  handleOnClear = () => this.setState({ fileUploaded : {} as Blob});

  formatDate = (date: string, DATE_FORMAT:string) => {
    return moment(date).format(DATE_FORMAT);
  };

  onSelectChatBox = (selectedId:string) =>{
    this.setState({selectedChatId: selectedId},()=>{
      this.getPerticularUsersChat(selectedId)
    })
  }

  getPerticularUsersChat = async(id:string) => {
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: await getStorageData("LoginToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPerticularUsersChatAPIcall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPerticularUsersChatEndPoint}${id}`);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
