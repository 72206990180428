import React from "react";

// Customizable Area Start
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import HeaderNavigation from "../../navigationmenu/src/HeaderNavigation.web";
import {
    Box, Typography, Tabs,
    Tab, MenuItem, Menu
} from "@mui/material"
import { groupImg, btnImg ,noNotificationImg} from './assets'
import DoneAllIcon from '@mui/icons-material/DoneAll';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
// Customizable Area End

import PushnotificationsController, {
    Props,
} from "./PushnotificationsController";

export default class Pushnotifications extends PushnotificationsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        const open = Boolean(this.state.anchorEl);
        return (
            // Customizable Area Start
            <>
                <Box sx={webStyles.container}>
                    <HeaderNavigation navigation={this.props.navigation} id={""} />
                    <Box sx={webStyles.content}>
                        <NavigationMenu navigation={this.props.navigation} id={""} />
                        {(this.state.tabValue === 2 && this.state.notificationData.length === 0) ? (
                            <Box sx={webStyles.mainContent}>
                            <Box sx={webStyles.cardContainer}>
                                <Typography sx={webStyles.headerFont}>Notifications</Typography>
                                <Box sx={webStyles.readText}>
                                    <Box sx={{ fontSize: '20px',fontFamily: 'Inter', fontWeight: 400, color: '#334155' }}>
                                        You have {this.state.unreadNumber} unread notifications
                                    </Box>

                                    <Box  sx={{ cursor: 'pointer' }} onClick={this.readAllNotification}> <DoneAllIcon sx={{ fontSize: '16px', marginRight: '4px', marginTop: '3px' }} />Marked all as read
                                    </Box>
                                </Box>

                                <Box sx={webStyles.root}>
                                    <Tabs
                                        value={this.state.tabValue}
                                        data-testId={"tabChange"}
                                        onChange={this.handleChange}
                                        TabIndicatorProps={{ style: { display: 'none' } }}
                                        sx={webStyles.tabs}
                                    >
                                        <Tab label = "All" sx = {webStyles.tab} />
                                        <Tab label = "New" sx = {webStyles.tab} />
                                        <Tab label = "Unread" sx = { webStyles.tab} />
                                    </Tabs>
                                </Box>
                                <Box sx={{display:'flex', opacity:0.5, textAlign:'center',justifyContent:'center',}}>
                                    <Box sx = { webStyles.headingone} >You have no unread notifications!</Box>
                                </Box>
                            </Box>
                            </Box>
                            )
                            : 
                               
                                <>
                                { this.state.notificationData.length > 0 && (
                        <Box sx={webStyles.mainContent}>
                            <Box sx={webStyles.cardContainer}>
                                    <>
                                        <Typography sx={webStyles.headerFont}>Notifications</Typography>
                                        
                                        <Box sx={webStyles.readText}>
                                            <Box sx={{fontSize:'20px',fontWeight:400,fontFamily:'Inter',color:'#334155'}}>
                                            You have {this.state.unreadNumber} unread notifications
                                            </Box>
                                            
                                            <Box onClick = {this.readAllNotification} sx={{cursor:'pointer'}}> <DoneAllIcon sx={{ fontSize: '16px', marginRight: '4px', marginTop: '3px' }} />Marked all as read 
                                            </Box>
                                            </Box>

                                        <Box sx={webStyles.root}>
                                            <Tabs
                                                data-testId={"tabChange"}
                                                value={this.state.tabValue}
                                                sx={webStyles.tabs}
                                                onChange={this.handleChange}
                                                TabIndicatorProps={{ style: { display: 'none' } }}
                                            >
                                                <Tab label="All" sx={webStyles.tab} />
                                                <Tab label="New" sx={webStyles.tab} />
                                                <Tab label="Unread" sx={webStyles.tab} />
                                            </Tabs>
                                        </Box>

                                        <Box sx={webStyles.mainContaienr}>
                                            {
                                                this.state.notificationData.map((items, index) => (
                                                    <Box sx={webStyles.boxStyle} key={index}>
                                                        <Box sx={webStyles.topContent}>
                                                            <Box sx={webStyles.headings}>
                                                                <img style={{ background: '#FBE1C4', borderRadius: '8px' }} src={btnImg} alt="" />
                                                                <Typography sx={webStyles.text1}>{items?.attributes?.headings}</Typography>
                                                            </Box>
                                                            <Box sx={webStyles.time}>{this.timeAgo(items?.attributes?.created_at)}</Box>

                                                        </Box>

                                                        <Box sx={webStyles.box2Style}>
                                                            <Box sx={webStyles.message}>{items?.attributes?.contents}</Box>
                                                            <Box onClick={this.handleClick} sx={{ marginRight: '30px' }}><MoreVertIcon />
                                                            </Box>
                                                            <Menu
                                                                id="demo-positioned-menu"
                                                                aria-labelledby="demo-positioned-button"
                                                                anchorEl={this.state.anchorEl}
                                                                open={open}
                                                                onClose={this.handleclose}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}
                                                                sx={{

                                                                    // transform: 'translateX(-100px)',
                                                                }}
                                                            >
                                                                <MenuItem sx={{
                                                                    fontSize: '14px',
                                                                    fontFamily: 'Inter',
                                                                    margin: '3px',
                                                                    marginTop: '0px',
                                                                    borderRadius: '8px',
                                                                    '&:hover': {
                                                                        backgroundColor: '#FDF1E2',
                                                                        color:'#0F172A'
                                                                    },
                                                                }}
                                                                data-test-id = "readNotification"
                                                                 onClick={()=>this.readOneNotfication(items?.id)}><CheckCircleOutlineIcon sx={{
                                                                    height: '14px',
                                                                    marginRight: '5px',  width: '14px',
                                                                    color:'#0F172A'
                                                                }} />Mark as read</MenuItem>

                                                                <MenuItem sx={{
                                                                    fontSize: '14px',
                                                                    fontFamily: 'Inter',
                                                                    margin: '3px',
                                                                    borderRadius: '8px',
                                                                    '&:hover': {
                                                                        backgroundColor: '#FDF1E2',
                                                                        color:'#0F172A'
                                                                    },
                                                                }}
                                                                data-test-id = "delNotification"
                                                                 onClick={()=>this.deleteNotification(items?.id)}> <DeleteOutlineIcon sx={{
                                                                    height: '18px',
                                                                    marginRight: '5px', width: '14px',
                                                                    color:'#0F172A'
                                                                }} />Delete Notification</MenuItem>
                                                            </Menu>


                                                        </Box>
                                                    </Box>
                                                ))
                                            }
                                        </Box>

                                    </>


                            </Box>
                        </Box>
                            )} 
                            {this.state.notificationData.length === 0 && (<Box style={{ ...webStyles.noNotification, flexDirection: 'column' }}>
                                <img src={noNotificationImg} alt="No notification" />
                                <Box>
                                    <Typography sx={{ ...webStyles.headerFont, color: "#1E293B", textAlign: 'center', marginBottom: '30px' }}>No Notifications yet!</Typography>
                                    <Typography sx={webStyles.text}>
                                        We’ll notify you once we have <br />
                                        something for you
                                    </Typography>
                                </Box>
                            </Box>)}
                            
                            </>
                        }                      
                    </Box>
                </Box>

            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyles = {
    noNotification:{
        display:'flex',
        gap:'50px',
        alignItems: 'center', margin: 'auto',
    },
    root: {
        flexGrow: 1,
        backgroundColor: 'white',
        display: 'flex',
        gap: '6px',
        marginTop: '3%',
    },
    tabs: {
        borderRadius: '50px',
        backgroundColor: '#E2E8F0',
        color: '#64748B',
        padding: '6px 12px 6px 12px',
    },
    tab: {
        width: '140px',
        padding: '6px 12px 6px 12px',
        borderRadius: '50px',
        fontFamily: "Inter",
        fontSize: '14px',
        fontWeight: 700,
        textTransform: 'initial',
        color: '#64748B',
        '&.Mui-selected': {
            backgroundColor: '#FDF1E2',
            color: '#B57730',
        },
    },
    container: {
        display: "flex",
        overflowX: 'hidden',
        flexDirection: "column",
    },
    content: {
        flex: 1,
        display: "flex",
    },
    mainContent: {
        backgroundColor: '#E2E8F0',
        flex: 1,
        padding: "2rem",
    },
    cardContainer: {
        border: '2px solid #215089',
        backgroundColor: '#FFFFFF',
        borderRadius: '16px',
        padding: '2rem',
        height: '98%'
    },
    termsAndCon: {
        width: '1036px',
        fontSize: '20px',
        fontWeight: 400,
        color: '#0F172A',
        lineHeight: '35px',
        fontFamily: 'Inter',
        mt: '2.7rem',
        marginLeft: '7px'
    },
    headerFont: {
        fontFamily: "Inter",
        fontSize: '30px',
        fontWeight: 700,
        color: "#215089"
    },
    centerContainer: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    headingone: {
        fontSize: '30px',
        fontWeight: 700,
        fontFamily: 'Inter',
        color: '#1E293B',
        marginTop: '30px',
        marginBottom: '30px'
    },
    text: {
        fontSize: '24px',
        fontWeight: 400,
        color: '#0F172A',
        fontFamily: 'Inter',
        textAlign: 'center'
    },
    readText: {
        fontSize: '16px',
        display: 'flex',
        justifyContent: 'space-between',
        fontFamily: 'inter',
        marginTop:'2%',
        color:'#334155'
    },
    mainContaienr: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    boxStyle: {
        marginTop: '4%',
        boxShadow: '0px 4px 4px 0px #00000040',
        border: '1px solid white',
        borderRadius: '16px',
    },
    topContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    headings: {
        margin: '10px',
        marginTop: '30px',
        display: 'flex',
        flexDirection: 'row',
        gap: '30px'
    },
    time: {
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 400,
        color: '#475569',
        marginRight: '30px',
        marginTop: '8px'
    },
    text1: {
        fontSize: '16px',
        fontFamily: 'Inter'
        , fontWeight: 700,
        color: '#215089'
    },
    box2Style: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    message: {
        fontSize: '14px',
        fontWeight: 700,
        color: '#0F172A',
        fontFamily: 'Inter',
        padding: '10px',
        paddingLeft: '63px',
        paddingTop: '8px',
        paddingBottom: '19px'
    }
}


// Customizable Area End
