// Customizable Area Start
import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  MenuItem,
  FormHelperText,
  Box
} from "@mui/material";
import Select from '@mui/material/Select';

import TruckerDetailsController, {
  Props,
} from "./TruckerDetailsController.web";

import UnauthLayout from "../../../components/src/UnauthLayout.web";
import {
  PageTitle,
} from "../../../components/src/CustomText.web";
import CustomTextInput from "../../../components/src/CustomTextInput.web";
import CustomButton from "../../../components/src/CustomButton.web.";
import CustomToast from "../../../components/src/CustomToast.web";
import { AuthPageTitle } from "../../../components/src/AuthPageTitle.web";
import CheckIcon from '@mui/icons-material/Check';
import OutlinedInput from '@mui/material/OutlinedInput';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  createTheme,
  Theme,
} from "@mui/material/styles";
function getStyles(name: string, personName:  string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const validationSchema = Yup.object({
  insurance: Yup.string()
    .trim()
    .required("Insurance name is required.")
    .min(2, "Insurance name must be at least 2 characters long.")
    .max(50, "Insurance name must be between 2 and 50 characters long.")
    .matches(
      /^[a-z ,.'-]+$/i,
      "Insurance name must not contain numbers or special characters."
    ),
  driverlicense: Yup.string()
    .trim()
    .required("Driver license is required.")
    .min(2, "Driver license must be at least 2 characters long.")
    .max(50, " Driver license must be between 2 and 50 characters long."),
  mcnumber: Yup.string()
    .trim()
    .required("MC number is required.")
    .min(2, "MC number is too short. Please enter a valid number.")
    .max(50, "MC number is too long. Please enter a valid number."),
  authority: Yup.string()
    .trim()
    .required("Authority is required.")
    .min(2, "Authority must be at least 2 characters long.")
    .max(50, "Authority must be between 2 and 50 characters long.")
    .matches(
      /^[a-z ,.'-]+$/i,
      "Authority must not contain numbers or special characters."
    ),

    truck: Yup.array().required('Truck Type is required')
});

const newStyles = {
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  
  input: {
    height: '56px',
    radius: '8px',
    padding: '10px 8px 10px 8px',
    width: '100%'
  },
  newBox:{
    textAlign:'center',
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-evenly',
    alignItems:'center',
    gap:'30px',
    marginTop:'50%'
    },
    newText:{
    marginTop:'40px',
    fontFamily:"Arial, Helvetica",
    fontSize:'20px',
    fontWeight:400,
    lineHeight:'35px',
    width:'70%'
    } ,
    btn:{
    width:'100%',
    marginTop:'30px'
    },
    successTitile:{
      fontSize:'36px',
      fontWeight:700,
      fontFamily:"Arial, Helvetica",
    },
    inputContainer: {
      paddingHorizontal: '8px',
      paddingVertical: '10px',
      height: '56px',
      borderRadius: 12,
      width: '371px',
      fontSize: 16,
      fontFamily: 'Inter',
      fontWeight: '400',
      color:'#94A3B8'
    },
     
};
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export class TruckerDetails extends TruckerDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start

    return (
      <>
        <UnauthLayout currentWidth={this.state.width}>
        {!this.state.succesPage &&
          <Formik
            initialValues={{
              insurance: "",
              driverlicense: "",
              mcnumber: "",
              authority: "",
              truck: [] as string[],
            }}
            validationSchema={validationSchema}
            onSubmit={this.handleSignUP}
            
            data-test-id="trucker-details-formik"
          >
            {(props) => {
              this.updateRegisterFormProps(props);

              return (
                <Form
                  data-test-id="refister-form"
                  style={newStyles.form as React.CSSProperties}
                  onSubmit={props.handleSubmit}
                >

                  {!!this.state.errorMessage && (
                    <CustomToast message={this.state.errorMessage} />
                  )}

                  <PageTitle component="h2" variant="2xl">
                    Sign Up
                  </PageTitle>
                  {<AuthPageTitle>Truck Type <span style={{ color: 'red' }}>*</span></AuthPageTitle>}
                  <FormControl sx={{ width: 371,  }}>
                            <Select
                              data-test-id = "truckerType"
                              multiple
                              displayEmpty
                              name = "truck"
                              value={props.values.truck}
                              onChange={(event) => this.handleChange2(event, props)}
                              input={<OutlinedInput />}
                              renderValue={(selected:any) => {
                                if (selected.length === 0) {
                                  return <p>Select</p>;
                                }

                                return selected.join(', ');
                              }}
                              MenuProps={MenuProps}
                              style={{...newStyles.inputContainer,height:'60px',borderRadius:'8px',}}
                              inputProps={{ 'aria-label': 'Without label' }}
                              IconComponent={KeyboardArrowDownIcon} 
                              sx={{
                                '& .MuiSvgIcon-root': {
                                  color: 'black'
                                },
                                '.MuiOutlinedInput-notchedOutline': {
                                  borderColor: !!this.getFieldError2('truck', props) ? '#F87171' : '', 
                                },
                              }}
                    
                            >
                              <MenuItem disabled value="">
                                <p>Select</p>
                              </MenuItem>
                              {this.truckTypeList.map((name:string) => (
                                <MenuItem
                                  key={name}
                                  value={name}
                                  style={getStyles(name, props.values.truck, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText sx={{ color: '#DC2626',fontFamily:'Inter',fontSize:'12px',margin:'0px' }}>{this.getFieldError2('truck', props)}</FormHelperText>
                            </FormControl>
                  <Field
                    component={CustomTextInput}
                    label="Insurance"
                    data-test-id="txtInputInsurance"
                    placeholder="Insurance name"
                    defaultValue={props.values.insurance}
                    onChange={this.handleChange("insurance", props)}
                    error={this.getFieldError("insurance", props)}
                  />
                  <Field
                    component={CustomTextInput}
                    label="Driver License"
                    data-test-id="txtInputDriverLicense"
                    placeholder="License number"
                    defaultValue={props.values.driverlicense}
                    onChange={this.handleChange("driverlicense", props)}
                    error={this.getFieldError("driverlicense", props)}
                  />

                  <Field
                    component={CustomTextInput}
                    label="MC Number"
                    data-test-id="txtInputMCNumber"
                    placeholder="MC number"
                    defaultValue={props.values.mcnumber}
                    onChange={this.handleChange("mcnumber", props)}
                    error={this.getFieldError("mcnumber", props)}
                  />
                  <Field
                    component={CustomTextInput}
                    label="Authority"
                    data-test-id="txtInputAuthority"
                    placeholder="authority"
                    defaultValue={props.values.authority}
                    onChange={this.handleChange("authority", props)}
                    error={this.getFieldError("authority", props)}
                  />
                  <Box sx={{marginTop:'20px'}}/>
                  <CustomButton
                    text="Done"
                    testId="btnEmailRegister"
                    onClick={props.handleSubmit}
                  />
                </Form>
              );
            }}
          </Formik>
        }
          {
            this.state.succesPage &&
              (
                <>
                <Box sx={newStyles.newBox}>
                <Box component="h2" sx={newStyles.successTitile}>
                  {'Congratulations !'}
                </Box>
                <Box>
                  <CheckIcon style={{color:'white',width:'50.23px',height:'50.07',border:'2px solid #34D399',borderRadius:'40px',background:'#34D399'}} />
                </Box>
                <Box sx={newStyles.newText}>
                {'Your account has been  successfully created.'}
                </Box>

                <Box sx={newStyles.btn}>
                    <CustomButton
                    testId="btnContinue"
                    onClick={this.sendDashBoard}
                    text="CONTINUE"
                  />
                    </Box>
                </Box>
                </>
              )
          }
        </UnauthLayout>

      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default TruckerDetails;
// Customizable Area End
